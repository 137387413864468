import * as yup from 'yup'

export const PASSWORD_REGEXP = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,32}$/

export default yup.object().shape({
  email: yup.string().required('this field is required').email('not valid email'),
  roleId: yup.number().required('this field is required'),
  userName: yup
    .string()
    .required('this field is required')
    .min(3, 'minimum 3 symbols')
    .max(64, 'maximum 64 symbols'),
    password: yup.string().when('id', {
      is: (id) => !id, // If id is null or undefined
      otherwise: (schema) => schema.notRequired().nullable(), // Password is optional
      then: (schema) =>
        schema
          .required('this field is required')
          .matches(
            PASSWORD_REGEXP,
            'Password must be at least 8 characters long and include uppercase and lowercase letters, and a special symbol'
          )
          .min(8, 'minimum 8 symbols')
          .max(64, 'maximum 64 symbols'),
    }),
    confirmPassword: yup.string().when('password', {
      is: (password) => !!password, // If password is provided, confirmPassword should match
      then: (schema) =>
        schema
          .required('this field is required')
          .oneOf([yup.ref('password')], "Passwords don't match!")
          .min(8, 'minimum 8 symbols')
          .max(64, 'maximum 64 symbols'),
      otherwise: (schema) => schema.notRequired().nullable(), // If password is not required, neither is confirmPassword
    }),
})