import axios from 'axios'

export const getUserNotificationsList = async (page, take) => {
  const response = await axios({
    url: '/notifications',
    method: 'GET',
    params: {
      page,
      take,
    },
  })

  return response.data.data
}

export const getUserNotificationById = async (notificationId) => {
  const response = await axios.get(`/notifications/${notificationId}`)

  return response.data.data
}

export const getReadNotificationUsersList = async ({ notificationId, page, take }) => {
  const response = await axios.get(`/notifications/${notificationId}/read`, {
    params: {
      page,
      take,
    },
  })

  return response.data.data
}
