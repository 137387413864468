import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  deleteUsersNotification,
  getUsersNotification,
  updateUsersNotification,
} from 'redux/actions/usersNotifications'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Typography, Table } from 'antd'

import useClient from 'hooks/useClient'

import Page from 'components/Page'
import UserNotificationForm from 'components/forms/UserNotificationForm'
import { PERMISSIONS } from 'utils/constants'

import {
  getReadNotificationUsersList,
  getUserNotificationById,
} from '../../../api/user-notificaitons'

const { Title } = Typography

const UsersNotificationsView = () => {
  const dispatch = useDispatch()
  const { isClientCan } = useClient()
  const navigate = useNavigate()
  const { id } = useParams()


  useEffect(() => {
    dispatch(getUsersNotification({ id }))
  }, [id, dispatch])

  const {
    item: usersNotification,
    isGetLoading,
    isChangeLoading,
  } = useSelector((state) => state.usersNotifications)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  const isClientCanDeleteNotifications = isClientCan(PERMISSIONS.DELETE_NOTIFICATIONS)

  const { data: notification, isFetching } = useQuery(
    ['user-notification', id],
    async () => await getUserNotificationById(id),
  )

  const {
    data: users,
    isFetching: isUsersFetching,
    isError: isUsersError,
  } = useQuery(
    ['user-notification-read-users-list', id, pageSize, currentPage],
    async () =>
      await getReadNotificationUsersList({ notificationId: id, page: currentPage, take: pageSize }),
  )

  const deleteHandler = (id) =>
    dispatch(
      deleteUsersNotification({
        id,
        navigate,
      }),
    )

  const submitHandler = async (id, data) => {
    const avatarFile = data.avatar || null
    const preparedData = {
      body: data.body,
      landingPage: data.landingPage,
      title: data.title,
    }

    if (data.landingPage === 'LINK') {
      preparedData.url = data.url
    } else {
      preparedData.product = data.product
    }

    dispatch(
      updateUsersNotification({
        id,
        data: preparedData,
        avatarFile,
        navigate,
      }),
    )
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 57,
      render: (_, __, index) => index + 1 + pageSize * (currentPage - 1),
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      align: 'center',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
  ]

  const handleTableChange = (paginate) => {
    setCurrentPage(paginate.current)
    setPageSize(paginate.pageSize)
  }

  return (
    <Page
      pageTitle={notification?.title}
      deleteHandler={isClientCanDeleteNotifications ? () => deleteHandler(id) : null}
      isGetLoading={isFetching}
      isChangeLoading={isFetching}
    >
      <UserNotificationForm
        defaultValues={notification}
        submitHandler={(data) => submitHandler(id, data)}
        loading={isFetching}
      />
      <Title level={4}>Users</Title>
      <Table
        columns={columns}
        dataSource={isUsersFetching || isUsersError ? [] : users?.items}
        rowKey={(record) => record.id}
        style={{ height: 'auto' }}
        onChange={handleTableChange}
        loading={isUsersFetching}
        pagination={{
          pageSize,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,

          total: isUsersFetching || isUsersError ? 0 : users?.total,
        }}
      />
    </Page>
  )
}

export default UsersNotificationsView
