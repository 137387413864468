import axios from 'axios'

export const getCurrentAdminUser = async () => {
  const response = await axios.get('admin/user/current')
  return response.data.data
}

export const updateCurrentAdminUser = async (data) => {
  const response = await axios.put('admin/user/current', data)
  return response.data.data
}

export const requestResetAdminUserPassword = async (id) => {
  const response = await axios.post(`auth/admin/${id}/forgot-password`)

  return response.data.data
}

export const resetAdminUserPassword = async ({ token, password }) => {
  const response = await axios.post(`auth/admin/reset-password`, { token, password })

  return response.data.data
}

export const getUsersList = async (currentPage, pageSize, sortType, sortOrder, roleId, search) => {
  const response = await axios({
    url: 'admin/user/list',
    method: 'GET',
    params: {
      page: currentPage,
      take: pageSize,
      asc: sortType && sortOrder === 'ascend' ? sortType : null,
      desc: sortType && sortOrder === 'descend' ? sortType : null,
      filterByRoleId: roleId,
      search: search ? search : null,
    },
  })
  return response.data.data
}

export const createAdminUser = async (data) => {
  const response = await axios.post(`admin/user`, data)
  return response.data.data
}

export const updateAdminUser = async ({ id, data }) => {
  const response = await axios.put(`admin/user/${id}`, data)
  return response.data.data
}

export const getAdminUser = async (id) => {
  const response = await axios.get(`admin/user/${id}`)
  return response.data.data
}

export const activateAdminUser = async (id) => {
  const response = await axios.put(`admin/user/activate/${id}`)
  return response.data.data
}

export const deactivateAdminUser = async (id) => {
  const response = await axios.put(`admin/user/deactivate/${id}`)
  return response.data.data
}
