import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getUsersNotifications, sendUsersNotification } from 'redux/actions/usersNotifications'
import useClient from 'hooks/useClient'
import { useNavigate } from 'react-router-dom'
import useEnvironment from 'hooks/useEnvironment'
import { sortByAlphabet, sortByDate } from 'utils/sorters'
import { DATE_FORMAT, ICONS, MODAL_TYPES, PERMISSIONS } from 'utils/constants'
import SortableColumnTitle from 'components/SortableColumnTitle'
import PageTable from 'components/PageTable'
import Icon from 'components/Icon'
import Button from 'components/Button'
import PlayButton from 'components/PlayButton'
import SettingsButton from 'components/SettingsButton'
import { showModal } from 'redux/actions/modal'
import { getUserNotificationsList } from '../../../api/user-notificaitons'
import { useQuery } from '@tanstack/react-query'
import { checkLastPushDateWithinOneDay } from 'utils/functions'

const UsersNotifications = () => {
  const { t } = useTranslation()
  const { isClientCan } = useClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { environment } = useEnvironment()
  const { items, pagination, isGetLoading } = useSelector((state) => state.usersNotifications)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  const isClientCanCreateNotifications = isClientCan(PERMISSIONS.CREATE_NOTIFICATIONS)
  const isClientCanSendNotifications = isClientCan(PERMISSIONS.SEND_NOTIFICATIONS)
  const isClientCanUpdateNotifications = isClientCan(PERMISSIONS.UPDATE_NOTIFICATIONS)

  const refreshUserNotifications = (page, size) => dispatch(getUsersNotifications({ page, size }))

  useEffect(() => {
    if (!items.length) {
      refreshUserNotifications(pagination.current, pagination.pageSize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { data, isFetching, isError } = useQuery(
    ['user-notification-list', currentPage, pageSize],
    async () => await getUserNotificationsList(currentPage, pageSize),
  )

  const playHandler = useCallback(
    (data) => {
      if (!data) {
        return
      }
      const { id, type, field, number } = data
      dispatch(
        sendUsersNotification({
          id,
          data: {
            type,
            field,
            number,
          },
          env: environment,
        }),
      )
    },
    [environment, dispatch],
  )

  const settingHandler = useCallback(
    (id) => {
      dispatch(
        showModal({
          type: MODAL_TYPES.FORM,
          notificationId: id,
          submitHandler: playHandler,
        }),
      )
    },
    [dispatch, playHandler],
  )

  const columns = useMemo(() => {
    const notificationsColumns = []
    notificationsColumns.push({
      title: '№',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => id.slice(0, 5), // Make id short
    })
    notificationsColumns.push({
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('fields.notification title')}
          id="notificationTitle"
        />
      ),
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sortByAlphabet(a.title, b.title),
    })
    notificationsColumns.push({
      title: (props) => (
        <SortableColumnTitle
          {...props}
          title={t('fields.notification text')}
          id="notificationText"
        />
      ),
      dataIndex: 'body',
      key: 'body',
    })
    notificationsColumns.push({
      title: 'Reads',
      dataIndex: 'reads',
      key: 'reads',
      align: 'center',
    })
    notificationsColumns.push({
      title: (props) => <SortableColumnTitle {...props} title={t('fields.date')} id="date" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => sortByDate(a.createdAt, b.createdAt),
      render: (createdAt) => createdAt && moment(createdAt).format(DATE_FORMAT),
      defaultSortOrder: 'descend',
    })
    if (isClientCanSendNotifications) {
      notificationsColumns.push({
        key: 'actions',
        width: '35px',
        render: (record) => {
          
          if (!record.lastPushDate || record.lastPushDate && checkLastPushDateWithinOneDay(record.lastPushDate))
            return <PlayButton playHandler={() => playHandler({ id: record.id })} />
        },
      })
    }
    if (isClientCanSendNotifications) {
      notificationsColumns.push({
        key: 'setting',
        width: '35px',
        render: (record) => <SettingsButton settingHandler={() => settingHandler(record.id)} />,
      })
    }

    return notificationsColumns
  }, [isClientCanSendNotifications, playHandler, settingHandler, t])

  const handleTableChange = (paginate) => {
    setCurrentPage(paginate.current)
    setPageSize(paginate.pageSize)
  }

  return (
    <PageTable
      pageTitle={t('usersNotifications.title')}
      action={
        isClientCanCreateNotifications ? (
          <Button type="primary" onClick={() => navigate(`/main/users-notifications/add`)}>
            <Icon icon={ICONS.PLUS} className="btn-icon-suffix" />
            <span className="btn-text">{t('usersNotifications.add button')}</span>
          </Button>
        ) : null
      }
      baseData={isFetching || isError ? [] : data.result}
      columns={columns}
      onRow={
        isClientCanUpdateNotifications
          ? (record) => {
              return {
                onClick: (event) => {
                  const playButton = event.target.closest('.playButton')
                  const settingsButton = event.target.closest('.settingsButton')
                  if (playButton || settingsButton) {
                    return null
                  }
                  navigate(`/main/users-notifications/view/${record.id}`)
                },
              }
            }
          : null
      }
      onChange={handleTableChange}
      loading={isFetching}
      pagination={{
        pageSize,
        current: currentPage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,

        total: isFetching || isError ? 0 : data.total,
      }}
    />
  )
}

export default UsersNotifications
