export const getCorrectErrorMessages = (errors = []) => {
  return errors.map((item) => {
    switch (item.error) {
      case 'AUTH_PASSWORDS_DO_NOT_MATCH':
        return 'Passwords do not match'
      case 'AUTH_INCORRECT_CREDENTIALS':
        return 'The email address or password is incorrect'
      case 'Not Found': {
        if (item?.message === 'ADMIN_IS_NOT_ACTIVE') {
          return 'The admin account is deactivated, please contact administration'
        } else return item.error
      }
      default:
        return item.error
    }
  })
}
