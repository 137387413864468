export const ICONS = {
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  YOUTUBE: 'YOUTUBE',
  TWITTER: 'TWITTER',
  ARROW_DOWN: 'ARROW_DOWN',
  CLOSE: 'CLOSE',
  ALERT: 'ALERT',
  PLUS: 'PLUS',
  SEARCH: 'SEARCH',
  USER: 'USER',
  PRODUCT: 'PRODUCT',
  PROMOTIONS: 'PROMOTIONS',
  BANNERS: 'BANNERS',
  SURVEY: 'SURVEY',
  ORDERS: 'ORDERS',
  USERS: 'USERS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  EXIT: 'EXIT',
  ASCEND: 'ASCEND',
  DESCEND: 'DESCEND',
  ARROW_LEFT: 'ARROW_LEFT',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
  VISIBLE: 'VISIBLE',
  INVISIBLE: 'INVISIBLE',
  TRASH: 'TRASH',
  EYE: 'EYE',
  UPLOAD: 'UPLOAD',
  IMAGE: 'IMAGE',
  PLAY: 'PLAY',
  MENU_OUTLINED: 'MENU_OUTLINED',
  MOSQUE: 'MOSQUE',
  RATING: 'RATING',
  COUPON: 'COUPON',
  STAR_EMPTY: 'STAR_EMPTY',
  SETTINGS: 'SETTINGS',
  CHEVRON_UP: 'CHEVRON_UP',
  CHEVRON_DOWN: 'CHEVRON_DOWN',
  CHEVRON_LEFT: 'CHEVRON_LEFT',
  CHEVRON_RIGHT: 'CHEVRON_RIGHT',
  DOWNLOAD: 'DOWNLOAD',
  MORE: 'MORE',
  EDIT: 'EDIT',
  CUSTOMERS_NEW: 'CUSTOMERS_NEW',
  ROLES: 'ROLES',
  LOGS: 'LOGS',
  PRODUCT_CATALOG: 'PRODUCT_CATALOG',
  PRODUCT_NEW: 'PRODUCT_NEW',
  CATEGORIES: 'CATEGORIES',
  MARKETING: 'MARKETING',
  OFFERS: 'OFFERS',
  FLYERS: 'FLYERS',
  PROMOTIONS_NEW: 'PROMOTIONS_NEW',
  RATING_NEW: 'RATING_NEW',
  UPSELLING: 'UPSELLING',
  CAMPAIGNS: 'CAMPAIGNS',
  CASHBACK: 'CASHBACK',
}

export const MODAL_TYPES = {
  CONFIRM: 'CONFIRM',
  ALERT: 'ALERT',
  FORM: 'FORM',
}

export const ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
}

export const ORDER_STATUSES = {
  ALL: 'All',
  UNDER_PROCCESS: 'Under process',
  OUT_OF_DELIVERY: 'Out of delivery',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
  CANCELED: 'Canceled',
  DELETED: 'Deleted',
}
export const PAYMENT_STATUSES = {
  WAITING_FOR_PAYMENT: 'Waiting for payment',
  PAID: 'Paid',
  COUPON_COLLECTION: 'Coupon collection',
}
export const PAYMENT_METHODS = {
  CASH: 'Cash',
  KNET: 'K-net',
  COUPON: 'Coupon',
  APPLE_PAY: 'Apple Pay',
  CREDIT_CARD: 'Credit card',
}

export const PROMOCODE_TYPES = {
  MONEY: {
    KEY: 'MONEY',
    LABEL: 'KD',
  },
  PERCENT: {
    KEY: 'PERCENT',
    LABEL: '%',
  },
}

export const PROMOCODE_STATUSES = {
  IN_PROGRESS: 'Active',
  INACTIVE: 'Inactive',
  EXPIRED: 'Expired',
  AWAITING: 'Awaiting',
}

export const DASHBOARD_PERIODS = {
  AM: 'AM',
  PM: 'PM',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  CUSTOM_DATE: 'CUSTOM_DATE',
}

export const DASHBOARD_PERIODS_LABELS = {
  AM: 'AM',
  PM: 'PM',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
  CUSTOM_DATE: 'Custom date',
}

export const DRAGGABLE_BODY_ROW = 'DraggableBodyRow'
export const DATE_FORMAT = 'DD.MM.YY'
export const DATE_DAY_MONTH_FORMAT = 'D MMM'
export const DATE_MONTH_FORMAT = 'MMM'
export const DATE_MONTH_YEAR_FORMAT = 'MMM YYYY'
export const DATE_WEEK_FORMAT = 'ddd'
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
export const DATE_HOURS_FORMAT = 'HH:mm'
export const DATE_DAY_MONTH_HOURS_FORMAT = 'D MMM HH:mm'

export const PERMISSIONS = {
  READ_LOGS: 'READ_LOGS',

  READ_USERS: 'READ_USERS',

  READ_FILES: 'READ_FILES',
  DELETE_FILES: 'DELETE_FILES',
  UPDATE_FILES: 'UPDATE_FILES',

  READ_ROLES: 'READ_ROLES',
  DELETE_ROLES: 'DELETE_ROLES',
  UPDATE_ROLES: 'UPDATE_ROLES',
  CREATE_ROLES: 'CREATE_ROLES',

  READ_FLIERS: 'READ_FLIERS',
  DELETE_FLIERS: 'DELETE_FLIERS',
  UPDATE_FLIERS: 'UPDATE_FLIERS',
  CREATE_FLIERS: 'CREATE_FLIERS',

  READ_RATING: 'READ_RATING',
  DELETE_RATING: 'DELETE_RATING',
  UPDATE_RATING: 'UPDATE_RATING',
  CREATE_RATING: 'CREATE_RATING',

  READ_ORDERS: 'READ_ORDERS',
  SEND_ORDERS: 'SEND_ORDERS',
  UPDATE_ORDERS: 'UPDATE_ORDERS',

  READ_OFFERS: 'READ_OFFERS',
  CREATE_OFFERS: 'CREATE_OFFERS',
  UPDATE_OFFERS: 'UPDATE_OFFERS',

  READ_COUPONS: 'READ_COUPONS',
  DELETE_COUPONS: 'DELETE_COUPONS',
  UPDATE_COUPONS: 'UPDATE_COUPONS',
  CREATE_COUPONS: 'CREATE_COUPONS',

  READ_MOSQUES: 'READ_MOSQUES',
  UPDATE_MOSQUES: 'UPDATE_MOSQUES',

  READ_PRODUCTS: 'READ_PRODUCTS',
  UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',

  READ_UPSELLING: 'READ_UPSELLING',
  UPDATE_UPSELLING: 'UPDATE_UPSELLING',

  READ_DASHBOARD: 'READ_DASHBOARD',

  READ_PROMOTIONS: 'READ_PROMOTIONS',
  UPDATE_PROMOTIONS: 'UPDATE_PROMOTIONS',

  READ_PROMO_CODES: 'READ_PROMO_CODES',
  DELETE_PROMO_CODES: 'DELETE_PROMO_CODES',
  UPDATE_PROMO_CODES: 'UPDATE_PROMO_CODES',
  CREATE_PROMO_CODES: 'CREATE_PROMO_CODES',

  READ_ADMIN_USERS: 'READ_ADMIN_USERS',
  DELETE_ADMIN_USERS: 'DELETE_ADMIN_USERS',
  CREATE_ADMIN_USERS: 'CREATE_ADMIN_USERS',

  READ_NOTIFICATIONS: 'READ_NOTIFICATIONS',
  SEND_NOTIFICATIONS: 'SEND_NOTIFICATIONS',
  DELETE_NOTIFICATIONS: 'DELETE_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  CREATE_NOTIFICATIONS: 'CREATE_NOTIFICATIONS',

  READ_PRODUCT_CATEGORIES: 'READ_PRODUCT_CATEGORIES',
  UPDATE_PRODUCT_CATEGORIES: 'UPDATE_PRODUCT_CATEGORIES',

  UPDATE_WALLET: 'UPDATE_WALLET',

  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  READ_CAMPAIGN: 'READ_CAMPAIGN',
  UPDATE_CAMPAIGN: 'UPDATE_CAMPAIGN',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',

  DEACTIVATE_USER: 'DEACTIVATE_USER',
}

export const ADMIN_ROLE_ID = 1

export const CATALOG = {
  RATING_MESSAGE: 'Rating message',
  NOTIFICATIONS: 'Notifications',
  CATEGORIES: 'Categories',
  PROMOTIONS: 'Promotions',
  PROMOCODES: 'Promocodes',
  UPSELLING: 'Upselling',
  PRODUCTS: 'Products',
  COUPONS: 'Coupons',
  MOSQUES: 'Mosques',
  FLIERS: 'Flyers',
  OFFERS: 'Offers',
  ORDERS: 'Orders',
  AUTH: 'Auth',
}

export const CASHBACK_RULE_LABEL = {
  PER_PRODUCT: 'Per Product',
  PER_TWO_PRODUCTS: 'Per 2 products',
  EVERY_THREE: 'Every 3',
  EVERY_FIVE: 'Every 5',
  EVERY_TEN: 'Every 10',
  ONLY_FIVE: 'Only 5',
  ONLY_TEN: 'Only 10',
}

export const REPORT_TYPES = {
  BASIC: 'Basic',
  DETAILED: 'Detailed',
}
