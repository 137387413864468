import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { userNotificationSchema } from 'utils/schemas'
import HookedField from 'components/HookedField'
import { getNotificationProducts } from 'redux/actions/usersNotifications'
import AvatarFormField from '../AvatarFormField'
import Button from '../Button'
import 'assets/styles/forms/_notificationsForm.scss'
import { checkLastPushDateWithinOneDay } from 'utils/functions'

const UserNotificationForm = ({ defaultValues = {}, submitHandler, loading }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { products, isProductsLoading } = useSelector((state) => state.usersNotifications)
  const [isAvatarChanged, setIsAvatarChanged] = useState(false)

  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(userNotificationSchema),
  })

  const avatarField = methods.register('avatar')
  const pageType = methods.watch('landingPage', defaultValues.landingPage || 'MAIN_PAGE')

  const formClasses = classNames({
    userNotificationForm: true,
    'userNotificationForm--newRecord': !defaultValues?.id,
  })

  const fetchProducts = (pageType) => dispatch(getNotificationProducts({ pageType }))

  useEffect(() => {
    if (['SKU_PAGE', 'OFFER_PAGE'].indexOf(pageType) !== -1) {
      fetchProducts(pageType)
    }
  }, [pageType])

  useEffect(() => {
    const forceProductId =
      defaultValues?.landingPage === pageType && defaultValues?.product?.id
        ? defaultValues?.product.id
        : 1
    methods.setValue('product', forceProductId)
  }, [products, pageType, defaultValues])

  const selectOptions = [
    { label: 'Main Page', value: 'MAIN_PAGE' },
    { label: 'Cart Page', value: 'CART_PAGE' },
    { label: 'Offer Page', value: 'OFFER_PAGE' },
    { label: 'SKU Pages', value: 'SKU_PAGE' },
    { label: 'Mosque Page', value: 'MOSQUE_PAGE' },
    { label: 'Survey Page', value: 'SURVEY_PAGE' },
    { label: 'Contact us Page', value: 'CONTACT_US_PAGE' },
    { label: 'Link', value: 'LINK' },
  ]

  return (
    <div className={formClasses}>
      <FormProvider {...methods}>
        <form className="form" autoComplete="off" onSubmit={methods.handleSubmit(submitHandler)}>
          <div className="form-imgWrapper">
            <div className="form-imgWrapper">
              <AvatarFormField
                name={avatarField.name}
                file={defaultValues?.image}
                handleChange={(file) => {
                  methods.setValue(avatarField.name, file)
                  setIsAvatarChanged(true)
                }}
              />
            </div>
          </div>
          <div className={'main-fields-grid'}>
            {defaultValues?.id && (
              <div className={classNames(['main-fields-grid__item', 'main-fields-grid__item--id'])}>
                <HookedField
                  label={t('fields.id')}
                  name="id"
                  disabled={true}
                  defaultValue={defaultValues.id}
                  placeholder="id"
                  fieldType="textField"
                  type="text"
                />
              </div>
            )}
            <div className={classNames(['main-fields-grid__item', 'main-fields-grid__item--type'])}>
              <HookedField
                type="select"
                name="landingPage"
                defaultValue={defaultValues?.landingPage || ''}
                disabled={Boolean(defaultValues?.lastPushDate) && checkLastPushDateWithinOneDay(defaultValues?.lastPushDate)}
                placeholder={t('fields.notification type')}
                fieldType="selectField"
                isRequired={true}
                label={t('fields.notification type')}
                options={selectOptions}
              />
            </div>
            <div
              className={classNames(['main-fields-grid__item', 'main-fields-grid__item--title'])}
            >
              <HookedField
                label={t('fields.notification title en')}
                name="title"
                defaultValue={defaultValues?.title || ''}
                placeholder={t('fields.notification title en')}
                fieldType="textField"
                type="text"
                isRequired={true}
                disabled={Boolean(defaultValues?.lastPushDate) && checkLastPushDateWithinOneDay(defaultValues?.lastPushDate)}
              />
            </div>
            <div className={classNames(['main-fields-grid__item', 'main-fields-grid__item--body'])}>
              <HookedField
                label={t('fields.notification text en')}
                name="body"
                defaultValue={defaultValues?.body}
                placeholder={t('fields.notification text en')}
                fieldType="textField"
                type="textarea"
                isRequired={true}
                disabled={Boolean(defaultValues?.lastPushDate) && checkLastPushDateWithinOneDay(defaultValues?.lastPushDate)}
              />
            </div>
            {(pageType === 'SKU_PAGE' || pageType === 'OFFER_PAGE') && (
              <div
                className={classNames([
                  'main-fields-grid__item',
                  'main-fields-grid__item--relation',
                ])}
              >
                <HookedField
                  disabled={isProductsLoading}
                  type={'select'}
                  name={'product'}
                  defaultValue={defaultValues?.product?.id || null}
                  placeholder={t('fields.select product')}
                  fieldType={'selectField'}
                  label={t('fields.select product')}
                  isRequired={true}
                  options={products || []}
                />
              </div>
            )}
            {pageType === 'LINK' && (
              <div
                className={classNames([
                  'main-fields-grid__item',
                  'main-fields-grid__item--relation',
                ])}
              >
                <HookedField
                  type={'text'}
                  name={'url'}
                  defaultValue={defaultValues?.url || ''}
                  placeholder={t('fields.enter url')}
                  fieldType={'textField'}
                  label={t('fields.enter url')}
                />
              </div>
            )}
            <div
              className={classNames(['main-fields-grid__item', 'main-fields-grid__item--submit'])}
            >
              <Button
                className="save-btn"
                size="large"
                type="primary"
                htmlType="submit"
                disabled={(loading || !methods.formState.isDirty) && !isAvatarChanged}
              >
                {t('app.save')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default UserNotificationForm
